
.about-card{
    height: 100%;
    &-wrap{
        height: 100%;
        position: relative;
    }
    &__thumb{
        overflow: hidden;     
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: calc(100% - 12px);
            height:  calc(100% - 12px);
            top: 6px;
            left: 6px;
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-bottom-right-radius: 14px;
        }
    }
    &__active{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .about-card{
        &-wrap{
            &::before{
                content: "";
                display: block;
                position: absolute;
                width: 1px;
                height: 600px;
                background-color: rgba(255, 255, 255, 0.2);
                right: 0;
            }
        }
        &__caption{
            position: absolute;
            right: 10px;
        }
        &__thumb{
            &::after{
                content: "";
                width: calc(100% - 20px);
                height:  calc(100% - 20px);
                top: 10px;
                left: 10px;
                border-bottom-right-radius: 20px;
            }
        }
    }
    .about-card-group{
        .about-card-col:nth-child(2n){
            .about-card{
                &-wrap{
                    &::before{
                        top: 0;
                        transform: translateY(-100%);
                    }
                }
                &__caption{
                    top: -20px;
                    right: 10px;
                    transform: translateY(-100%);
                }
            }
        }
        .about-card-col:nth-child(2n - 1){
            margin-top: 40px;
            .about-card{
                &-wrap{
                    &::before{
                        bottom: 150px;
                        transform: translateY(100%);
                    }
                }
                &__caption{
                    bottom: -2px;
                    transform: translateY(100%);
                }
            }
        }
    }
}
@media (min-width:1024px){
    .about-card{
        &__contents{
            opacity: 1;
            visibility: visible;
            transition: 0.25s ease-out;
        }
        &__thumb{ 
            &__thumb{
                padding-bottom: 0;
                transition: 0.25s ease-out;
            }        
            &::after{
                border-bottom-right-radius: 36px;
                transform: scaleY(0);
                transition: 0.25s ease-out;
            }
        }
        &__active{
            opacity: 0;
            visibility: hidden;
            transition: 0.25s ease-out;
            >*{
                transform: scale(1.4);
                opacity: 0;
                visibility: hidden;
                transition: 0.25s ease-out;
            }
        }
        &:hover{
            .about-card__contents{
                opacity: 0;
                visibility: hidden;                
            }
            .about-card__thumb::after{
                transform: scaleY(1);
                transition: 0.5s 0.6s ease-out;              
            }
            .about-card__active{
                opacity: 1;
                visibility: visible;   
                >*{
                    transform: scale(1);
                    opacity: 1;
                    visibility: visible;  
                    transition: 0.5s ease-out;
                    &:nth-child(1){
                        transition-delay: 0.25s;
                    }
                    &:nth-child(2){
                        transition-delay: 0.35s;
                    }
                    &:nth-child(3){
                        transition-delay: 0.45s;
                    }
                    &:nth-child(4){
                        transition-delay: 0.55s;
                    }
                }      
            }
        }
    }
    .about-card-group{
        .about-card-col:nth-child(2n - 1){
            margin-top: 80px;
        }
    }
}
@media (min-width:1200px){
}

