
.icon-close{
    transform: rotate(45deg);
}
.icon--size {
    &-xx-small {
        height: 16px;
    }
    &-x-small {
        height: 18px;
    }
    &-small {
        height: 20px;
    }
    &-default {
        height: 22px;
    }
    &-large {
        height: 24px;
    }
    &-x-large {
        height: 26px;
    }
    &-xx-large {
        height: 28px;
    }
}
