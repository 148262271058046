
.main-visual {
    position: relative;
    > .container {
        max-width: var(--container-lg);
        padding: 0;
    }
    &__inner {
        position: relative;
        padding: var(--page-section-padding-y) var(--container-gutter);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &__center-image {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
    }
    &__info {
        position: relative;
        .icon-rounded-object {
            left: unset;
            right: 16px;
            top: calc(50% - 50px);
            opacity: 0.2;
        }
    }
    &__txt-bg {
        position: absolute;
        bottom: 0;
        left: 0;
    }
}
.fixed-contents__left {
    &__inner {
        position: relative;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual {
        > .container {
            max-width: calc(var(--container-lg) + var(--container-gutter) * 2);
        }
        &__inner {
            background-image: none !important;
        }
        &__bg {
            width: 100%;
            height: 100vh;
            top: 0;
            left: 0;
            position: absolute;
            background-image: url(/images/main/visual-bg.jpg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: -2;
            display: flex;
        }
        &__info {
            position: relative;
            .icon-rounded-object {
                right: 38px;
                top: calc(50% - 90px);
            }
        }
        .scroll-ani {
            position: fixed;
        }
    }
    .fixed-contents__left {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        &__inner {
            width: 100%;
            height: 100vh;
            display: flex;
            align-items: center;
            padding: 0 var(--container-gutter);
            max-width: calc(var(--container-lg) + var(--container-gutter) * 2);
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .fixed-contents__right {
        position: relative;
        z-index: 2;
    }
    .main-visual.active .fixed-contents__left {
        position: fixed;
    }
    .main-visual.active:not(.bottom) .main-visual__bg {
        position: fixed;
    }
    .main-visual.bottom .main-visual__bg {
        top: unset;
        bottom: 0;
    }
    .main-visual.bottom .fixed-contents__left {
        position: absolute !important;
        top: auto !important;
        left: 0 !important;
    }
    .main-visual.bottom .scroll-ani {
        position: absolute;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .main-visual.active:not(.bottom) .fixed-contents__left {
        padding-right: var(--header-width) !important;
    }
    .main-visual.active:not(.bottom) .main-visual__bg {
        width: calc(100% - var(--header-width));
    }
}
@media (min-width: 1544px) {
    .main-visual.active .fixed-contents__left {
        padding-right: 0 !important;
    }
}
